import React, { useState, useEffect } from 'react';


function Results() {
  const [s3File, sets3File] = useState([]);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  let countyname = params.get('county');
  let countynamequery = params.get('county');
  const type = params.get('type');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  if (countynamequery === 'New Hanover') {
    countynamequery = 'NewHanover';
    countyname = 'New Hanover';
  }


  const fetchPost = async () => {


    setLoading(true);
    const response = await fetch(
      '/api/s3query/' + countynamequery + '/' + type
    );

    try {
      const data = await response.json();
      if (data.length === 0) {
        setError(true);
      }
      sets3File(data);
      setLoading(false);
      console.log(data);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    fetchPost();
  }, []);

  return (
    <main id="content-container" className="content-container" role="main">
      <div>
        <div id="block-breadcrumbs">


          <nav className="breadcrumb" role="navigation" aria-labelledby="system-breadcrumb">
            <div className="breadcrumbs">
              <div className="wrapper">
                <ol itemScope="" itemType="http://schema.org/BreadcrumbList">
                  <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                    <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                      <a href="https://www.nccourts.gov"><span itemProp="name" title="Go back to: Home">Home</span></a>

                    </span>
                    <meta itemProp="position" content="1" loop-length="3" />
                  </li>
                  <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                    <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                      <a href="https://www.nccourts.gov/court-dates"><span itemProp="name" title="Go back to: Court Dates">Court Dates</span></a>

                    </span>
                    <meta itemProp="position" content="2" loop-length="3" />
                  </li>
                  <li className="parent" itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                    <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                      <a href="/"><span itemProp="name" title="Go back to:  Civil & Criminal Calendars">Civil & Criminal Calendars</span></a>

                    </span>
                    <meta itemProp="position" content="2" loop-length="3" />
                  </li>
                  <li itemProp="itemListElement" itemScope="" itemType="http://schema.org/ListItem">
                    <span itemScope="" itemType="http://schema.org/Thing" itemProp="item">
                      <span itemProp="name" title="Currently viewing: Civil & Criminal Calendars"> {type} Calendar for {countyname} County</span>

                    </span>
                    <meta itemProp="position" content="3" loop-length="3" />
                  </li>
                </ol>
              </div>
            </div>
          </nav>



        </div>

      </div>
      <article itemScope itemType="http://schema.org/WebPage">
        <header className="title title--primary" >
          <div className="wrapper">
            <h1 className="title__label" itemProp="headline">{type} Calendar for {countyname} County</h1>
            <p className="title__description" itemProp="description">View {countyname} County's upcoming {type} calendars.</p>
          </div>
        </header>
        <div id="block-tyler-vs-legacy">
          <div className="zone" itemProp="mainContentOfPage">
            <div className="wrapper">
              <div className="band__part">
                <div className="alert alert--primary" role="alert">
                  <p><b>Portal is recommended for searching court dates and most updated case information.  It is recommended you access eCourt Portal at the following link: <a href="https://portal-nc.tylertech.cloud/Portal/">https://portal-nc.tylertech.cloud/Portal/</a>.</b></p>

                  <div>
                    To use the search feature for the calendar(s) posted below for court dates:
                    <ol>
                      <li>Click on the applicable calendar link below to search for your court date.</li>
                      <li>Click the "Ctrl" + "F" keys to search the calendar for specific search criteria (name, case number, etc.).</li>
                      <li>Type the desired search criteria (name, case number, etc.) in the search box that appears.</li>
                      <li>Press enter.</li>
                      <li> Use the search box controls to scroll through the search results.</li>
                    </ol>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="zones">
          <div className="zone" itemProp="mainContentOfPage">
            <div className="band">
              <div className="wrapper">
                <div className="band__parts">
                  <div className="band__part">
                    <div>{loading ? <div className="loading">
                      <svg className="progress progress--spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="33" cy="33" r="30"></circle>
                      </svg>
                    </div> : <></>}</div>


{type === 'Criminal' ?
  <h2>{countyname} District Criminal Calendar</h2> : <></>}

{(s3File.length === 0 && type === 'Criminal') ? (
  <div><strong>No Calendars Found</strong></div>
) : (
  <ul>
    {s3File.slice(0).reverse().map((item) => {
      if (item.court === 'District' && type === 'Criminal') {
        return (
          <li key={item.id}> {/* Apply key here */}
            <a href={item.fileUrl} title={item.fileName}>{item.fileName}</a>
          </li>
        );
      }
      return null; // To ensure nothing is returned when condition isn't met
    })}
  </ul>
)}

{type === 'Criminal' ?
  <h2>{countyname} Superior Criminal Calendar</h2> : <></>}

{(s3File.length === 0 && type === 'Criminal') ? (
  <div><strong>No Calendars Found</strong></div>
) : (
  <ul>
    {s3File.slice(0).reverse().map((item) => {
      if (item.court === 'Superior' && type === 'Criminal') {
        return (
          <li key={item.id}> {/* Apply key here */}
            <a href={item.fileUrl} title={item.fileName}>{item.fileName}</a>
          </li>
        );
      }
      return null; // To ensure nothing is returned when condition isn't met
    })}
  </ul>
)}

{type === 'Civil' ?
  <h2>{countyname} Civil District Calendar</h2> : <></>}

{(s3File.length === 0 && type === 'Civil') ? (
  <div><strong>No Calendars Found</strong></div>
) : (
  <ul>
    {s3File.slice(0).reverse().map((item) => {
      if (item.court === 'District' && type === 'Civil') {
        return (
          <li key={item.id}> {/* Apply key here */}
            <a href={item.fileUrl} title={item.fileName}>{item.fileName}</a>
          </li>
        );
      }
      return null; // To ensure nothing is returned when condition isn't met
    })}
  </ul>
)}

{type === 'Civil' ?
  <h2>{countyname} Civil Superior Calendar</h2> : <></>}

{(s3File.length === 0 && type === 'Civil') ? (
  <div><strong>No Calendars Found</strong></div>
) : (
  <ul>
    {s3File.slice(0).reverse().map((item) => {
      if (item.court === 'Superior' && type === 'Civil') {
        return (
          <li key={item.id}> {/* Apply key here */}
            <a href={item.fileUrl} title={item.fileName}>{item.fileName}</a>
          </li>
        );
      }
      return null; // To ensure nothing is returned when condition isn't met
    })}
  </ul>
)}




                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </main>

  );

}

export default Results;

